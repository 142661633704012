<template>
  <b-container fluid>
    <router-view />
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'AuthorizationLayout',
  mounted () {
    core.index()
  }
}
</script>
